import { Link } from 'gatsby';
import React, { FC } from 'react';

import App from '../components/App';

// markup
const ContactPage: FC = () => (
  <App>
    <div className="m-auto p-24">
      <ul>Oops this page doesn&apos;t exist !</ul>
      <Link to="\">Go back to the main page</Link>
    </div>
  </App>
);

export default ContactPage;
